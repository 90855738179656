/*
* PRODUCTION
* au
* https://www.ziptrak.com.au/api
* https://www.ziptrak.com.au
* ---
* nz
* https://www.nz.ziptrak.com/api
* https://www.nz.ziptrak.com/en
* -----------------------------
* STAGING
* au
* https://www.usence.com.au/australia/api
* https://www.usence.com.au/australia
* ---
* nz
* https://usence.com.au/new-zealand/api
* https://www.nz.ziptrak.com/en
* */

export const Countries = [
	{ code: 'au', name: 'Australia', data_store: 'https://www.usence.com.au/australia/api', link: 'https://www.usence.com.au/australia' },
	{ code: 'nz', name: 'New Zealand', data_store: 'https://usence.com.au/new-zealand/api', link: 'https://www.nz.ziptrak.com/en' }
];

export const COOKIE_LINK = 'https://cdn-cookieyes.com/client_data/20706a7f1846dffabba70920/script.js';
export const DEVELOP_API_LINK = 'https://api.logissoftware.com';

export const IS_DEVELOPMENT = (): boolean => {
	const devEnvs = [
		'logissoftware',
		'localhost',
		'192'
	];
	const url = new URL(window.location.href);
	const domainArray = url.hostname.split('.');

	return domainArray.some(value => devEnvs.includes(value));
};

export const CONFIG = {
	breakpoints: {
		'small-phone': 375,
		phone: 599,
		'tablet-portrait': 600,
		'tablet-landscape': 992,
		desktop: 1280,
		'l-desktop': 1400,
		'xl-desktop': 1530,
		'full-hd': 1980
	}
};

export const MAX_RETRY_ATTEMPTS = 5;
export const ATTEMPTS_DELAY_MS = 3000;

/*
* MOBILE RESPONSIVE RESOLUTIONS
* */
export const BREAKPOINTS: any = {
	'small-phone': {
		min: '(min-width: 0px)',
		max: '(max-width: 375px)'
	},
	phone: {
		min: '(min-width: 375px)',
		max: '(max-width: 599px)'
	},
	'phone-portrait': {
		min: '(min-width: 599px)',
		max: '(max-width: 850px)'
	},
	'tablet-portrait': {
		min: '(min-width: 850px)',
		max: '(max-width: 992px)'
	},
	desktop: {
		min: '(min-width: 992px)',
		max: '(max-width: 1200px)'
	},
	'l-desktop': {
		min: '(min-width: 1200px)',
		max: '(max-width: 1400px)'
	},
	'xl-desktop': {
		min: '(min-width: 1400px)',
		max: '(max-width: 1600px)'
	},
	'full-hd': {
		min: '(min-width: 1600px)',
		max: '(max-width: 1920px)'
	},
	'full-screen': {
		min: '(min-width: 600px)',
		max: '(max-width: 1920px)'
	}
};

/*
* CONDITIONS FOR INTERIOR FRAME TOP STYLE
* */
export const INTERIOR_FRAME_TOP_STYLE = [
	{ id: 6, width: 2000, height: 1200, name: 'blind.pelmet.95mm' },
	{ id: 6, width: 0, height: 1600, name: 'blind.pelmet.95mm' },
	{ id: 5, width: 0, height: 0, name: 'blind.pelmet.75mm' }
];

/*
* TOP TUBE
* */
export const TOP_TUBE = [
	'IDLER_COVER',
	'TUBE',
	'BEARING_HOUSING',
];

export const SHUTTER_CONTROL = [
	'MATERIAL-2',
	'LARGE_WEATHER_STRIP',
	'CUSTOM_MADE_SKIRT',
	'BOTTOM_BAR',
	'REVERSE_HANDLE',
	'SPLINE_RIGHT',
	'SPLINE_LEFT',
	'ALUMINIUM_FLAT_BAR_LEFT',
	'ALUMINIUM_FLAT_BAR_RIGHT',
	'CENTER_LOCK_RELEASE',
	'BOTTOM_BAR_GUIDE_LEFT',
	'BOTTOM_BAR_GUIDE_RIGHT',
	'STANDARD_WHEATER_STRIP'
];

/*
* HEXAGONAL PELMET
* */
export const TRADITIONAL_PELMET = [
	'TRADITIONAL_PELMET_CENTER',
	'TRADITIONAL_PELMET_RIGHT',
	'TRADITIONAL_PELMET_LEFT',
];

/*
* CAST BRACKET
* */
export const CAST_BRACKET = [
	'CAST_BRACKET_LEFT',
	'CAST_BRACKET_RIGHT',
];

/*
* CAST BRACKET WITH BACK FLASHING
* */
export const CAST_BRACKET_2 = [
	'CAST_BRACKET_LEFT',
	'CAST_BRACKET_RIGHT',
	'CAST_BRACKET_BACK_FLASHING'
];

/*
* SQUARE PELMET
* */
export const TOP_STYLE_PELMET = [
	'STREAMLINE_PELMET_CENTER',
	'STREAMLINE_PELMET_RIGHT',
	'STREAMLINE_PELMET_LEFT'
];

/*
* ALL PELMET PARTS
* */
export const GLOBAL_PELMET = [
	'TRADITIONAL_PELMET_CENTER',
	'TRADITIONAL_PELMET_LEFT',
	'TRADITIONAL_PELMET_RIGHT',
	'CAST_BRACKET_LEFT',
	'CAST_BRACKET_BACK_FLASHING',
	'CAST_BRACKET_RIGHT',
	'STREAMLINE_PELMET_CENTER',
	'STREAMLINE_PELMET_RIGHT',
	'STREAMLINE_PELMET_LEFT',
];

export const COLOR_FRAME_PELMET = [
	'REVEAL_FIX_CHANNEL_LEFT',
	'REVEAL_FIX_CHANNEL_RIGHT',
	'STREAMLINE_PELMET_CENTER',
	'STREAMLINE_PELMET_RIGHT',
	'STREAMLINE_PELMET_LEFT',
	'FACE_FIX_CHANNEL_RIGHT',
	'FACE_FIX_CHANNEL_LEFT',
	'TRADITIONAL_PELMET_CENTER',
	'TRADITIONAL_PELMET_LEFT',
	'TRADITIONAL_PELMET_RIGHT',
	'CAST_BRACKET_LEFT',
	'CAST_BRACKET_RIGHT',
	'CAST_BRACKET_BACK_FLASHING',
	'BOTTOM_BAR',
	'TRACK_LEFT',
	'TRACK_RIGHT'
];

export const INTERIOR_COLOR_FRAME_PELMET = [
	'BOTTOM BAR',
	'FACE FIX CHANNEL 1',
	'FACE FIX CHANNEL 2',
	'FACE FIX STOPPER 2',
	'FACE FIX STOPPER 1',
	'FRONT PELMET FOAM',
	'PELMET FRONT',
	'PELMET TOP',
	'REVEAL CHANNEL 2',
	'REVEAL CHANNEL 1',
	'TRACK ALUMINIUM 1',
	'TRACK ALUMINIUM 2',
	'BOTTOM CHANNEL REVEAL FIX',
	'BOTTOM CHANNEL FACE FIX 1',
	'BOTTOM CHANNEL FACE FIX 2',
	'BOTTOM CHANNEL FACE FIX 3'
];

export const INTERIOR_COLOR_FIXTURES = [
	'BOTTOM BAR GUIDE 1',
	'BOTTOM BAR GUIDE 2',
	'END CAP 1',
	'END CAP 2',
	'PELMET END TRIM 1',
	'PELMET END TRIM 2',
];

export const INTERIOR_END_CAPS = [
	'END CAP 1',
	'END CAP 2'
];

export const SPRING_BALANCE = [
	'ALUMINIUM_FLAT_BAR_LEFT',
	'ALUMINIUM_FLAT_BAR_RIGHT',
	'BOTTOM_BAR_MOTORIZE_PLUG_LEFT',
	'BOTTOM_BAR_TONGUE_LEFT',
	'BOTTOM_BAR_MOTORIZE_PLUG_RIGHT',
	'BOTTOM_BAR_TONGUE_RIGHT',
	'BOTTOM_STOP_LEFT',
	'BOTTOM_STOP_RIGHT',
	'CENTER_LOCK_RELEASE',
];

export const REVERSE_HANDLE = [
	'REVERSE_HANDLE'
];

export const BOTTOM_CHANNEL_HANDLE = [
	'BOTTOM CHANNEL REVEAL FIX',
	'BOTTOM CHANNEL FACE FIX 1',
	'BOTTOM CHANNEL FACE FIX 2',
	'BOTTOM CHANNEL FACE FIX 3'
];

export const GLOBAL_HIDDEN_PELMET = [
	'TRADITIONAL_PELMET_CENTER',
	'TRADITIONAL_PELMET_LEFT',
	'TRADITIONAL_PELMET_RIGHT',
	'CAST_BRACKET_BACK_FLASHING',
	'CAST_BRACKET_LEFT',
	'CAST_BRACKET_RIGHT',
	'LARGE_WEATHER_STRIP',
	'CUSTOM_MADE_SKIRT',
	'REVERSE_HANDLE',
	'FACE_FIX_CHANNEL_RIGHT',
	'FACE_FIX_CHANNEL_LEFT',
];

export const FACE_FIX = [
	'FACE_FIX_CHANNEL_RIGHT',
	'FACE_FIX_CHANNEL_LEFT',
];

export const FACE_FIX_INTERIOR = [
	'FACE FIX CHANNEL 1',
	'FACE FIX CHANNEL 2',
	'FACE FIX STOPPER 2',
	'FACE FIX STOPPER 1',
];

export const FACE_FIX_STOPPER_INTERIOR = [
	'FACE FIX STOPPER 2',
	'FACE FIX STOPPER 1',
];

export const REVEAL_CHANNEL = [
	'REVEAL_FIX_CHANNEL_RIGHT',
	'REVEAL_FIX_CHANNEL_LEFT',
];

export const REVEAL_CHANNEL_INTERIOR = [
	'REVEAL CHANNEL 1',
	'REVEAL CHANNEL 2'
];

// ALL BOTTOM BAR PARTS
export const GLOBAL_STRIP = [
	'STANDARD_WHEATER_STRIP',
	'LARGE_WEATHER_STRIP',
	'CUSTOM_MADE_SKIRT'
];

export const MATERIAL_MESH = [
	'MATERIAL-1',
	'MATERIAL-2',
];

export const SPLINE_MESH = [
	'SPLINE_RIGHT',
	'SPLINE_LEFT',
	'SPLINE_TOP_RIGHT',
	'SPLINE_TOP_LEFT',
	'MATERIAL_BOTTOM'
];

export const BLACK_PLASTIC_MESH = [
	'TOP_STOP_LEFT',
	'TOP_STOP_RIGHT',
	'CENTER_LOCK_RELEASE',
	'BOTTOM_STOP_LEFT',
	'BOTTOM_STOP_RIGHT',
	'BOTTOM_BAR_GUIDE_LEFT',
	'BOTTOM_BAR_MOTORIZE_PLUG_LEFT',
	'BOTTOM_BAR_TONGUE_LEFT',
	'BOTTOM_BAR_GUIDE_RIGHT',
	'BOTTOM_BAR_MOTORIZE_PLUG_RIGHT',
	'BOTTOM_BAR_TONGUE_RIGHT',
	'GUIDE_FUNNEL_RIGHT',
	'GUIDE_FUNNEL_LEFT',
	'TRACK_BOTTOM_CAP_LEFT',
	'TRACK_BOTTOM_CAP_RIGHT',
	'TUBE',
	'IDLER_COVER',
	'BEARING_HOUSING'
];

export const ALUMINIUM_MESH = [
	'ALUMINIUM_FLAT_BAR_LEFT',
	'ALUMINIUM_FLAT_BAR_RIGHT',
];

export const SCALING_MESHES = {
	1: 'MATERIAL-1',
	2: 'MATERIAL-2',
	3: 'MATERIAL_BOTTOM',
	8: 'REVEAL_FIX_CHANNEL_LEFT',
	11: 'STREAMLINE_PELMET_CENTER',
	20: 'REVEAL_FIX_CHANNEL_RIGHT',
	22: 'STANDARD_WHEATER_STRIP',
	35: 'ALUMINIUM_FLAT_BAR_LEFT',
	53: 'ALUMINIUM_FLAT_BAR_RIGHT',
	65: 'LARGE_WEATHER_STRIP',
	71: 'FACE_FIX_CHANNEL_LEFT',
	74: 'TRADITIONAL_PELMET_CENTER',
	107: 'BOTTOM_BAR',
	112: 'CAST_BRACKET_BACK_FLASHING',
	117: 'FACE_FIX_CHANNEL_RIGHT',
	123: 'CUSTOM_MADE_SKIRT',
	136: 'TUBE',
	143: 'SPLINE_RIGHT',
	144: 'SPLINE_LEFT',
	147: 'TRACK_LEFT',
	148: 'TRACK_RIGHT'
};

/*
* ALL PARTS
* */
export const MESHES_IDS = {
	1: 'MATERIAL-1',
	2: 'MATERIAL-2',
	3: 'MATERIAL_BOTTOM',
	4: 'GUIDE_FUNNEL_LEFT',
	8: 'REVEAL_FIX_CHANNEL_LEFT',
	11: 'STREAMLINE_PELMET_CENTER',
	13: 'STREAMLINE_PELMET_LEFT',
	15: 'STREAMLINE_PELMET_RIGHT',
	18: 'GUIDE_FUNNEL_RIGHT',
	20: 'REVEAL_FIX_CHANNEL_RIGHT',
	22: 'STANDARD_WHEATER_STRIP',
	35: 'ALUMINIUM_FLAT_BAR_LEFT',
	38: 'BOTTOM_BAR_GUIDE_LEFT',
	39: 'BOTTOM_BAR_MOTORIZE_PLUG_LEFT',
	40: 'BOTTOM_BAR_TONGUE_LEFT',
	44: 'BOTTOM_BAR_GUIDE_RIGHT',
	45: 'BOTTOM_BAR_MOTORIZE_PLUG_RIGHT',
	46: 'BOTTOM_BAR_TONGUE_RIGHT',
	47: 'CENTER_LOCK_RELEASE',
	53: 'ALUMINIUM_FLAT_BAR_RIGHT',
	55: 'BOTTOM_STOP_LEFT',
	58: 'BOTTOM_STOP_RIGHT',
	65: 'LARGE_WEATHER_STRIP',
	71: 'FACE_FIX_CHANNEL_LEFT',
	74: 'TRADITIONAL_PELMET_CENTER',
	76: 'TRADITIONAL_PELMET_LEFT',
	78: 'TRADITIONAL_PELMET_RIGHT',
	82: 'TOP_STOP_LEFT',
	85: 'TOP_STOP_RIGHT',
	107: 'BOTTOM_BAR',
	110: 'CAST_BRACKET_LEFT',
	112: 'CAST_BRACKET_BACK_FLASHING',
	114: 'CAST_BRACKET_RIGHT',
	117: 'FACE_FIX_CHANNEL_RIGHT',
	123: 'CUSTOM_MADE_SKIRT',
	130: 'IDLER_COVER',
	136: 'TUBE',
	138: 'BEARING_HOUSING',
	142: 'REVERSE_HANDLE',
	143: 'SPLINE_RIGHT',
	144: 'SPLINE_LEFT',
	145: 'SPLINE_TOP_RIGHT',
	146: 'SPLINE_TOP_LEFT',
	147: 'TRACK_LEFT',
	148: 'TRACK_RIGHT',
	149: 'TRACK_BOTTOM_CAP_LEFT',
	150: 'TRACK_BOTTOM_CAP_RIGHT'
};

export const INTERIOR_MESHES_IDS = {
	109: 'BOTTOM BAR GUIDE 1',
	111: 'BOTTOM BAR GUIDE 2',
	113: 'BOTTOM BAR',
	116: 'END CAP 1',
	118: 'END CAP 2',
	124: 'FACE FIX CHANNEL 1',
	126: 'FACE FIX CHANNEL 2',
	128: 'FACE FIX STOPPER 2',
	129: 'FACE FIX STOPPER 1',
	132: 'FRONT PELMET FOAM',
	134: 'MATERIAL-1',
	135: 'MATERIAL-2',
	137: 'PELMET FRONT',
	139: 'PELMET TOP',
	140: 'REVEAL CHANNEL 1',
	141: 'REVEAL CHANNEL 2',
	156: 'TRACK ALUMINIUM 1',
	157: 'TRACK ALUMINIUM 2',
	159: 'WEATHER STRIP',
	160: 'PELMET END TRIM 1',
	151: 'PELMET END TRIM 2',
	152: 'BOTTOM CHANNEL REVEAL FIX',
	153: 'BOTTOM CHANNEL FACE FIX 1',
	154: 'BOTTOM CHANNEL FACE FIX 2',
	155: 'BOTTOM CHANNEL FACE FIX 3'
};

export const INTERIOR_SCALING_MESHES = {
	134: 'MATERIAL-1',
	135: 'MATERIAL-2',
	113: 'BOTTOM BAR',
	132: 'FRONT PELMET FOAM',
	137: 'PELMET FRONT',
	139: 'PELMET TOP',
	159: 'WEATHER STRIP',
	156: 'TRACK ALUMINIUM 1',
	157: 'TRACK ALUMINIUM 2',
	141: 'REVEAL CHANNEL 2',
	140: 'REVEAL CHANNEL 1',
	124: 'FACE FIX CHANNEL 1',
	126: 'FACE FIX CHANNEL 2',
	152: 'BOTTOM CHANNEL REVEAL FIX',
	154: 'BOTTOM CHANNEL FACE FIX 2'
};

export const INTERIOR_WIDTH_MESH = {
	134: 'MATERIAL-1',
	135: 'MATERIAL-2',
	113: 'BOTTOM BAR',
	132: 'FRONT PELMET FOAM',
	137: 'PELMET FRONT',
	139: 'PELMET TOP',
	159: 'WEATHER STRIP',
	152: 'BOTTOM CHANNEL REVEAL FIX',
	154: 'BOTTOM CHANNEL FACE FIX 2'
};

export const INTERIOR_WIDTH_POSITION_MESH = {
	109: 'BOTTOM BAR GUIDE 1',
	111: 'BOTTOM BAR GUIDE 2',
	116: 'END CAP 1',
	118: 'END CAP 2',
	124: 'FACE FIX CHANNEL 1',
	126: 'FACE FIX CHANNEL 2',
	129: 'FACE FIX STOPPER 1',
	128: 'FACE FIX STOPPER 2',
	141: 'REVEAL CHANNEL 2',
	140: 'REVEAL CHANNEL 1',
	156: 'TRACK ALUMINIUM 1',
	157: 'TRACK ALUMINIUM 2',
	160: 'PELMET END TRIM 1',
	151: 'PELMET END TRIM 2',
	153: 'BOTTOM CHANNEL FACE FIX 1',
	155: 'BOTTOM CHANNEL FACE FIX 3'
};

export const INTERIOR_HEIGHT_MESH = {
	135: 'MATERIAL-2',
	141: 'REVEAL CHANNEL 2',
	140: 'REVEAL CHANNEL 1',
	156: 'TRACK ALUMINIUM 1',
	157: 'TRACK ALUMINIUM 2',
	124: 'FACE FIX CHANNEL 1',
	126: 'FACE FIX CHANNEL 2'
};

export const INTERIOR_HEIGHT_POSITION_MESH = {
	134: 'MATERIAL-1',
	109: 'BOTTOM BAR GUIDE 1',
	111: 'BOTTOM BAR GUIDE 2',
	113: 'BOTTOM BAR',
	116: 'END CAP 1',
	118: 'END CAP 2',
	129: 'FACE FIX STOPPER 1',
	128: 'FACE FIX STOPPER 2',
	132: 'FRONT PELMET FOAM',
	137: 'PELMET FRONT',
	139: 'PELMET TOP',
	159: 'WEATHER STRIP',
	160: 'PELMET END TRIM 1',
	151: 'PELMET END TRIM 2',
	152: 'BOTTOM CHANNEL REVEAL FIX',
	153: 'BOTTOM CHANNEL FACE FIX 1',
	154: 'BOTTOM CHANNEL FACE FIX 2',
	155: 'BOTTOM CHANNEL FACE FIX 3'
};

export const MATERIAL_TEXTURE_SCALE = {
	outdoor: {
		'MATERIAL-1': { u: 38, v: 12 },
		'MATERIAL-2': { u: 1, v: 0.3 },
		CUSTOM_MADE_SKIRT: { u: 38, v: 12 }
	},
	interior: {
		'MATERIAL-1': { u: 130, v: 300 },
		'MATERIAL-2': { u: 300, v: 300 },
	}
};

export const TOP_STYLE_IDS = [
	{ id: 1, meshes: CAST_BRACKET },
	{ id: 3, meshes: TRADITIONAL_PELMET },
	{ id: 4, meshes: TOP_STYLE_PELMET },
	{ id: 2, meshes: CAST_BRACKET_2 }
];

export const BOTTOM_BAR_IDS = [
	{ id: 2, meshes: [MESHES_IDS[22]] },
	{ id: 3, meshes: [MESHES_IDS[65]] },
	{ id: 4, meshes: [MESHES_IDS[123]] }
];

export const MATERIAL_COLORS = [
	{ color: '#FBFBFB', name: 'Snow' },
	{ color: '#edf1ed', name: 'Shale Grey' },
	{ color: '#d2dbdb', name: 'Mist' },
	{ color: '#c1c9c2', name: 'Windspray' },
	{ color: '#ada195', name: 'Jasper' },
	{ color: '#e3dbbe', name: 'Bondi' },
	{ color: '#ddd9d1', name: 'Porcelain' },
	{ color: '#98b7b6', name: 'Neptune' },
	{ color: '#79908f', name: 'Steel Blue' },
	{ color: '#969b8f', name: 'Green Tea' },
	{ color: '#e1cdb1', name: 'Oyster' },
	{ color: '#bab4a8', name: 'Cappuccino' },
	{ color: '#aa9478', name: 'Taupe' },
	{ color: '#666155', name: 'Caramel' },
	{ color: '#6b6762', name: 'Basalt' },
	{ color: '#D9CAA3', name: 'Classic Cream' },
	{ color: '#615D52', name: 'Woodland Grey' },
	{ color: '#404040', name: 'Monument' },
	{ color: '#38444c', name: 'Gunmetal' },
	{ color: '#89987c', name: 'Evergreen' },
	{ color: '#BFAB92', name: 'Paperbark' },
	{ color: '#2D2825', name: 'Ebony' },
	{ color: '#514432', name: 'Cocoa' },
	{ color: '#6d3b3e', name: 'Chinaberry' },
	{ color: '#245445', name: 'Fed Green' }
];

export const FRAME_COLORS = [
	{ color: '#FBFBFB', name: 'Pearl White' },
	{ color: '#edf1ed', name: 'Shale Grey' },
	{ color: '#d2dbdb', name: 'Surfmist' },
	{ color: '#c1c9c2', name: 'Bushland' },
	{ color: '#ada195', name: 'Jasper' },
	{ color: '#e3dbbe', name: 'Magnolia' },
	{ color: '#ddd9d1', name: 'Shoji White' },
	{ color: '#98b7b6', name: 'Windspray' },
	{ color: '#79908f', name: 'Ironstone' },
	{ color: '#969b8f', name: 'Storm Grey' },
	{ color: '#e1cdb1', name: 'Stone Beige' },
	{ color: '#bab4a8', name: 'Dune' },
	{ color: '#aa9478', name: 'Doeskin' },
	{ color: '#666155', name: 'Hamersley Brown' },
	{ color: '#6b6762', name: 'Gully' },
	{ color: '#D9CAA3', name: 'Classic Cream' },
	{ color: '#615D52', name: 'Woodland Grey' },
	{ color: '#404040', name: 'Monument' },
	{ color: '#38444c', name: 'Deep Ocean' },
	{ color: '#89987c', name: 'Windspray' },
	{ color: '#BFAB92', name: 'Paperbark' },
	{ color: '#2D2825', name: 'Black Satin' },
	{ color: '#514432', name: 'Core Ten' },
	{ color: '#6d3b3e', name: 'Claret' },
	{ color: '#245445', name: 'Cottage Green' },
];

export const SELECTORS = {
	wrapper: '.mc-wrapper',
	overviewWrapper: '.mc-overview-wrap',
	header: '.mc-header',
	headerWrapper: '.mc-header-wrapper',
	render_canvas: 'renderCanvas',
	blind_list: '.mc-blind-list',
	blind_item: '.mc-blind-item',
	mobile_slider: '.mc-blind-mobile-slider',
	config_item: '.mc-config-item',
	config_item_content: '.mc-config-item__content',
	config_item_setup: '.mc-config-item__setup',
	blind_item_display: '.mc-blind-item__setup-display',
	config_item_title: '.mc-config-item__title',
	top_button: '.mc-top-btn',
	cursor: '.mc-cursor__container',
	navigation_item: '.mc-nav-menu li',
	request_button: '.mc-summary__block-link--request',
	overview_bottom_panel: '.mc-summary__block.panel',
	overview_add_block: '.mc-retailer__block.add',
	overview_section: '.mc-overview-section',
	tool_bar: '.mc-tool-bar',
	tool_bar_accordion: '.mc-tool-bar-accordion',
	tool_bar_controls: '.mc-tool-bar__controls',
	simplebar_content_wrapper: '.simplebar-content-wrapper',
	overlay_container: '.cdk-overlay-container',
	overlay_pane: '.cdk-overlay-pane',
	color_picker: '.mc-color-picker',
	operation_input: '.mc-operation__input',
	operation_label: '.mc-operation__block label',
	retailer: '.mc-retailer',
	retailer_form_section: '.mc-retailer__form-section',
	retailer_form_section_message: '.mc-retailer__form-section-message',
	background_project_samples: '.mc-select-background-modal__projects-samples-images',
	model_viewer: '.mc-model-viewer-wrapper',
	ar_viewer: '.mc-ar-viewer',
	ar_button: '.mc-ar-button',
	ar_close_button: '#default-exit-webxr-ar-button',
	view_switcher: '.mc-view-switcher',
	cookies_tag: '#cookieyes',
	quiz_icon: '.mc-modal__quiz-img',
	live_background: '.mc-live-background',
	slick_list: '.slick-list',
	slick_track: '.slick-track'
};

export const CLASSES = {
	loading: 'loading',
	live_background: 'mc-live-background'
};

export const STORAGE_NAMES = {
	zip_blind_data: 'zip-blind-data',
	zip_blind_type: 'zip-blind-type',
	zip_blind_config: 'zip-blind-config',
	zip_blind_text: 'zip-blind-text',
	zip_blind_session: 'zip-blind-session',
	zip_blind_session_saved: 'zip-blind-session-saved',
	zip_blind_temp_session: 'zip-blind-temp-session',
	zip_blind_progress_saved: 'zip-blind-progress-saved',
	zip_current_blind_id: 'zip-current-blind-id',
	zip_last_opened_blind_id: 'zip-last-opened-blind-id',
	zip_country_code: 'zip-country-code',
	zip_embedded_retailer: 'zip_embedded_retailer',
	zip_embed_key: 'zip_embed_key',
	zip_model_init: 'zip-model-init',
	default_model_size: 'default-model-size',
	zip_view_type: 'zip-view-type',
	zip_image_visualisation_status: 'zip-image-visualisation-status',
	zip_image_visualisation_background: 'zip-image-visualisation-background',
	zip_uploaded_image_visualisation_background: 'zip_uploaded_image_visualisation_background',
	zip_image_visualisation_screenshots: 'zip-image-visualisation-screenshots',
	zip_quiz_status: 'zip-quiz-status',
	zip_quiz_reviewed_status: 'zip-quiz-reviewed-status',
	zip_mobile_menu_status: 'zip-mobile-menu-status',
	zip_size_disclaimer: 'zip-size-disclaimer',
	zip_iv_type: 'zip-iv-type',
	zip_iv_screenshot: 'zip_iv_screenshot'
};

export const VIEW_TYPES = {
	design: 'design-blind',
	image_visualisation: 'image-visualisation'
};

export const CONTROL_TYPES = {
	move: 'move',
	rotate: 'rotate'
};

export const ROTATE_TYPES = {
	to_landscape: 'to_landscape',
	to_portrait: 'to_portrait'
};

export const DEVICES = {
	mobile: 'mobile',
	desktop: 'desktop'
};

export const INITIAL_MODEL_SIZES_IN_METERS = {
	outdoor: {
		width: 1.15,
		height: 0.7
	},
	interior: {
		width: 1,
		height: 0.6
	}
};

export const GOOGLE_ANALYTICS_SCRIPTS = [
	{innerHTML: '(function (w, d, s, l, i) {w[l] = w[l] || [];w[l].push({\'gtm.start\':new Date().getTime(), event: \'gtm.js\'});var f = d.getElementsByTagName(s)[0],j = d.createElement(s), dl = l != \'dataLayer\' ? \'&l=\' + l : \'\';j.async = true;j.src = \'https://www.googletagmanager.com/gtm.js?id=\' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, \'script\', \'dataLayer\', \'GTM-TG4MCXH\');'},
	{async: true, src: 'https://www.googletagmanager.com/gtag/js?id=G-QT4KXHD9F7'},
	{innerHTML: 'window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \'G-QT4KXHD9F7\');'}
];

export const ASSETS_LINKS = {
	images: 'assets/images/'
};

export const MINIMUM_MATERIAL_OPACITY = 0.9;
export const CANVAS_BACKGROUND_COLOR = '#F3F5F8';

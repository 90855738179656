import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { Location } from '@angular/common';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { EngineService } from '@core/services/engine/engine.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { TranslateService } from '@ngx-translate/core';
import { STORAGE_NAMES, SELECTORS, VIEW_TYPES, Countries, COOKIE_LINK, GOOGLE_ANALYTICS_SCRIPTS, IS_DEVELOPMENT, CLASSES } from '@root/app.config';
import { DisabledLinkModalComponent } from '@shared/modals/disabled-link-modal/disabled-link-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '@core/services/modal/modal.service';
import { IVSceneSettings } from '@root/app.interfaces';
import { IndexedDBService } from '@core/services/indexed-db/indexed-db.service';
import { BackgroundService } from '@core/services/background-service/background.service';
import { LinkUnavailableModalComponent } from '@shared/modals/link-unavailable-modal/link-unavailable-modal.component';


import * as _ from 'lodash';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
	title = '3dc';
	sessionKey = '';
	getCountry: Subscription;
	getCountryLink: Subscription;
	getRouteEvents: Subscription;
	getServerData: Subscription;
	getDefaultServerData: Subscription;
	getSessionLink: Subscription;
	getSessionData: Subscription;
	getRetailerRequest: Subscription;
	getActionBackPage: Subscription;
	getActivatedRouteRules: Subscription;
	getModelLoaded: Subscription;
	userVisitTime = 0;
	sceneCreated = false;
	countryLink = '';
	timerId: ReturnType<typeof setTimeout>;
	currentLink: string;
	
	constructor(
		private router: Router,
		private serverData: ServerDataService,
		private location: Location,
		private matDialog: MatDialog,
		private modalService: ModalService,
		private route: ActivatedRoute,
		private setupPageService: SetupPageService,
		private shareService: ShareService,
		private indexedDBService: IndexedDBService,
		private changeDetection: ChangeDetectorRef,
		private engineService: EngineService,
		private backgroundService: BackgroundService,
		private readonly translateService: TranslateService,
		private sessionStorageService: SessionStorageService
	) { }
	
	@HostListener('window:online', ['$event'])
	@HostListener('window:offline', ['$event'])
	networkStatus(event: Event) {
		this.shareService.setNetworkStatus({ event, status: event.type === 'online' });
	}
	
	setDefaultLang() {
		this.translateService.addLangs(['en']);
		this.translateService.setDefaultLang('en');
		
		const browserLanguage: any = this.translateService.getBrowserLang();
		this.translateService.use(browserLanguage.match(/en/) ? browserLanguage : 'en');
	}
	
	ngOnInit(): void {
		this.indexedDBService.deleteDB();
		this.cookieHandler();
		this.googleAnalyticsHandler();
		this.setDefaultLang();
		this.getRetailerId().then(() => this.bindEvent());
		
		this.getModelLoaded = this.shareService.getSceneCreated.subscribe(() => {
			if (this.countryLink && !this.sceneCreated || this.sessionKey) {
				this.getDefaultServerDataHandler();
			}
			
			this.sceneCreated = true;
			
			const isDesignType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
			const sizeDisclaimer = this.sessionStorageService.getSession(STORAGE_NAMES.zip_size_disclaimer);
			
			if (isDesignType) {
				this.engineService.setBackgroundImage(null);
				
				if (sizeDisclaimer) {
					this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_size_disclaimer);
				}
			}
			
			this.changeDetection.markForCheck();
		});
		
		this.getCountry = this.shareService.getCountry.subscribe(res => {
			this.countryLink = res;
			
			this.changeDetection.markForCheck();
		});
		
		this.getCountryLink = this.shareService.getCountryLink.subscribe(res => {
			this.countryLink = res;
			this.getDefaultServerDataHandler();
			
			this.changeDetection.markForCheck();
		});
	}
	
	ngOnDestroy(): void {
		this.getRouteEvents.unsubscribe();
		this.getServerData.unsubscribe();
		this.getDefaultServerData.unsubscribe();
		this.getSessionLink.unsubscribe();
		this.getSessionData.unsubscribe();
		this.getRetailerRequest.unsubscribe();
		this.getActivatedRouteRules.unsubscribe();
		this.getModelLoaded.unsubscribe();
		this.getCountry.unsubscribe();
		this.getCountryLink.unsubscribe();
	}
	
	bindEvent(): void {
		const getProductType = this.setupPageService.getProductType();
		const currentDomainLink = this.location.path().split('?');
		
		this.currentLink = currentDomainLink[0].split('/')[1] || 'home';
		const splitQuery = currentDomainLink[1]?.split('=');
		const sessionKeyQuery = splitQuery && splitQuery[0] !== 'country' ? currentDomainLink[1]?.split('=')[1] : '';
		this.sessionKey = this.parseQueryParams() ? sessionKeyQuery.split('&')[0] : sessionKeyQuery;
		
		this.getActivatedRouteRules = this.route.data.subscribe(() => {
			this.routeCurrentPageHandler('');
			this.removeAllSessionStorageData();
			this.checkActivePageHandler();
			
			if (!this.sessionKey && this.countryLink && getProductType === 'all') {
				this.setupPageService.sendBlindTypeForRender(true);
				setTimeout(this.setDefaultLoader.bind(this, true));
			}
			
			this.changeDetection.markForCheck();
		});
		
		this.getSessionLink = this.shareService.getLinkSessionKey.subscribe(res => {
			this.routeCurrentPageHandler(res);
			
			this.changeDetection.markForCheck();
		});
		
		this.getUserVisitTime();
		this.getActionBackPage = this.router.events
			.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
			.subscribe((events: RoutesRecognized[]) => {
				const prevUrl = events[0].urlAfterRedirects.split('?')[0];
				const curUrl = events[1].urlAfterRedirects.split('?')[0];
				
				if (prevUrl === '/overview' && curUrl === '/home') {
					setTimeout(() => this.shareService.setActionBackPage(true));
				}
			});
	}
	
	parseQueryParams(): boolean {
		const urlParams = new URLSearchParams(window.location.search);
		const countryCode = urlParams.get('country') || this.sessionStorageService.getSession(STORAGE_NAMES.zip_country_code);
		
		const hasCountryCode = this.setupPageService.validateCountryCodeHandler(countryCode?.toLowerCase());
		
		this.setupPageService.sendCountryCodeForRender(countryCode, hasCountryCode);
		
		return !!countryCode;
	}
	
	routeCurrentPageHandler(session: string): void {
		this.sessionKey = session ? session : this.sessionKey;
		
		const sessionKeyParam = {
			'': this.sessionKey,
			country: this.countryLink
		};
		const queryParam = this.sessionKey ? { queryParams: sessionKeyParam } : {};
		
		void this.router.navigate(
			[this.location.path().split('?')[0]],
			queryParam
		);
	}
	
	removeAllSessionStorageData(): void {
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_config);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_session_saved);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_data);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_text);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_session);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_current_blind_id);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_temp_session);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_progress_saved);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_type);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_image_visualisation_background);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_uploaded_image_visualisation_background);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_iv_screenshot);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_view_type);
	}

	removeEmbeddedLinkSessionStorageData(): void {
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_data);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_embedded_retailer);
	}
	
	removeSessionStorageData(): void {
		this.sessionStorageService.removeData(STORAGE_NAMES.zip_blind_type);
	}
	
	setDefaultLoader(status: boolean): void {
		const wrapper = document.querySelector(SELECTORS.wrapper);
		const overviewWrapper = document.querySelector(SELECTORS.overviewWrapper);
		const container = wrapper || overviewWrapper;
		
		container.classList.toggle(CLASSES.loading, status);
	}
	
	checkActivePageHandler(): void {
		if (this.currentLink === 'overview') {
			if (!this.sessionKey) {
				this.setEmptyBlindData(true);
			} else if (this.countryLink) {
				this.getDefaultServerDataHandler();
			}
			this.removeSessionStorageData();
		}
	}
	
	getDefaultServerDataHandler(): void {
		this.getDefaultServerData = this.serverData.GetServerData(this.countryLink, this.sessionKey).subscribe(res => {
			const storageConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
			this.sessionStorageService.setBlindData(res.session_key, STORAGE_NAMES.zip_blind_temp_session);
			this.sessionStorageService.setBlindData(res, STORAGE_NAMES.zip_blind_config);
			this.sessionStorageService.setBlindData(res.text, STORAGE_NAMES.zip_blind_text);
			
			if (res.retailer) {
				this.sessionStorageService.setBlindData(res.retailer, STORAGE_NAMES.zip_embedded_retailer);
				this.sessionStorageService.setBlindData(res.embed_key, STORAGE_NAMES.zip_embed_key);
				this.shareService.setEmbeddedRetailer(true);
			}
			
			if (!_.isEmpty(res.blinds)) {
				this.setBlindDataFromServer(res);
			} else {
				this.setEmptyBlindData();
			}
			
			const getProductType = this.setupPageService.getProductType();
			
			if (_.isEmpty(storageConfig) && getProductType !== 'all' && !res.blinds) {
				const id  = Math.floor(1000 + Math.random() * 9000);

				this.engineService.createScene(getProductType, id.toString());
				this.sessionStorageService.setBlindData(getProductType, STORAGE_NAMES.zip_blind_type);
				this.shareService.setBlindType({type: getProductType, id});
			}

			this.setIVSettingsFromServer(res.iv_scene_settings);
			
			this.shareService.setCountryPopup(true);
		}, error => {
			setTimeout(this.setDefaultLoader.bind(this, false));
			const errorModalComponent = this.matDialog.getDialogById('error-modal');

			if (errorModalComponent) {
				errorModalComponent.close();
				errorModalComponent.afterClosed().subscribe(() => this.openLinkUnavailableModalComponent(error));
			} else {
				this.openLinkUnavailableModalComponent(error);
			}
		});
	}

	openLinkUnavailableModalComponent(error: string): void {
		this.modalService.openResponsiveDialog({
			component: LinkUnavailableModalComponent,
			data: error.split('\n')[0].replace(/^\D+/g, ''),
			...this.modalService.getConfig(LinkUnavailableModalComponent.name)
		});
	}

	setIVSettingsFromServer(ivSceneSettings: IVSceneSettings): void {
		const ivCurrentBackground = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background);
		if (!ivSceneSettings || ivCurrentBackground) { return; }

		const storageConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
		const ivSceneUpload = ivSceneSettings.upload;
		const ivSceneSample = ivSceneSettings.sample;
		const ivLastType = ivSceneSettings.iv_last_type;

		if (ivSceneUpload) {
			this.sessionStorageService.setBlindData(ivSceneUpload, STORAGE_NAMES.zip_image_visualisation_background);
			this.sessionStorageService.setBlindData(ivSceneUpload, STORAGE_NAMES.zip_uploaded_image_visualisation_background);

			this.backgroundService.getIVImageFromSettings(ivSceneUpload.image).then((image) => {
				image = {...ivSceneUpload, ...image};
				this.indexedDBService.saveImage(image, STORAGE_NAMES.zip_image_visualisation_background);
			});
		}

		if (ivSceneSample) {
			this.sessionStorageService.setBlindData(ivSceneSample, STORAGE_NAMES.zip_image_visualisation_background);
			const sampleImage = storageConfig.iv_images[ivSceneSample.type][ivSceneSample.index].replace('/preview/', `/${ivSceneSample.image_orientation}_`);

			this.backgroundService.getIVImageFromSettings(sampleImage).then((image) => {
				image = {...ivSceneSample, ...image};
				this.indexedDBService.saveImage(image, STORAGE_NAMES.zip_image_visualisation_background);
			});
		}

		this.sessionStorageService.setBlindData(ivLastType, STORAGE_NAMES.zip_iv_type);

		if (ivLastType === 'live_background') {
			this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_image_visualisation_background);
		}
	}
	
	setBlindDataFromServer(res): void {
		let blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
		const sessionBlinds = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		
		if (this.sessionKey && _.isEmpty(sessionBlinds)) {
			this.sessionStorageService.setBlindData(res.blinds, STORAGE_NAMES.zip_blind_data);
		}
		
		this.sessionStorageService.setBlindData(true, STORAGE_NAMES.zip_blind_session_saved);
		this.sessionStorageService.setBlindData(res.session_key, STORAGE_NAMES.zip_blind_session);
		
		if (!blindType) {
			blindType = res.blinds[res.blinds.length - 1].type;
			this.sessionStorageService.setSession(blindType, STORAGE_NAMES.zip_blind_type);
		}
		
		this.shareService.setSessionBlindsData(true);
		
		this.shareService.setLoadingSpinner(false);
		const iVStatus = this.sessionStorageService.getSession(STORAGE_NAMES.zip_image_visualisation_status);
		setTimeout(this.setDefaultLoader.bind(this, !!iVStatus));
		setTimeout(this.engineService.displayModelHandler.bind(this.engineService));
		
		this.changeDetection.markForCheck();
		return;
	}
	
	setEmptyBlindData(status?: boolean): void {
		const getStorageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const getProductType = this.setupPageService.getProductType();
		
		if (!getStorageData && this.currentLink === 'overview') {
			void this.router.navigate(['/home']);
		}
		
		this.shareService.setLoadingSpinner(false);
		setTimeout(this.setDefaultLoader.bind(this, false));
		
		if (status) {
			this.parseQueryParams();
		} else if (!getStorageData && getProductType === 'all') {
			this.setupPageService.sendBlindTypeForRender(true);
		}
		
		this.changeDetection.markForCheck();
		return;
	}
	
	getUserVisitTime(): void {
		this.userVisitTimer();
		
		this.getRetailerRequest = this.shareService.getRetailerRequest.subscribe(() => {
			clearInterval(this.timerId);
			
			this.changeDetection.markForCheck();
		});
	}
	
	getRetailerId(): Promise<boolean> {
		this.removeEmbeddedLinkSessionStorageData();
		const setEmbeddedRetailerLoader = (status: boolean) => {
			const overviewWrapper = document.querySelector(SELECTORS.overviewWrapper);
			const mainWrapper = document.querySelector(SELECTORS.wrapper);
			const wrapper = overviewWrapper ?? mainWrapper;
			
			wrapper.classList.toggle(CLASSES.loading, status);
			wrapper.classList.toggle('full-opacity', status);
		};

		return new Promise((resolve) => {
			const urlParams = new URLSearchParams(window.location.search);
			const retailerKey = urlParams.get('embed') || this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embed_key);
			const embeddedRetailer = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer);

			if (urlParams.get('embed')) {
				window.history.pushState(null, null, window.location.pathname);
			}

			if (retailerKey && !embeddedRetailer) {
				setTimeout(setEmbeddedRetailerLoader.bind(this, true));
				this.sessionStorageService.setBlindData(retailerKey, STORAGE_NAMES.zip_embed_key);

				this.serverData.GetEmbeddedLink(retailerKey).subscribe((res) => {
					if (_.isEmpty(res)) {
						this.matDialog.open(DisabledLinkModalComponent, this.modalService.getConfig(DisabledLinkModalComponent.name));
						setEmbeddedRetailerLoader(false);
						return;
					}
					
					this.sessionStorageService.setBlindData(res, STORAGE_NAMES.zip_embedded_retailer);
					
					const countryCode = Countries.find((country) => country.name === res.country).code;
					this.sessionStorageService.setSession(countryCode, STORAGE_NAMES.zip_country_code);
					this.shareService.setCountryLink(countryCode);
					this.shareService.setEmbeddedStatus(true);
					
					setEmbeddedRetailerLoader(false);
					resolve(true);
				}, () => {
					setEmbeddedRetailerLoader(false);
				});
			} else {
				resolve(false);
			}
		});
	}
	
	userVisitTimer(): void {
		this.shareService.setUserTime(this.userVisitTime);
		
		this.timerId = setTimeout(() => {
			this.userVisitTime += 1;
			this.userVisitTimer();
		}, 1000);
	}
	
	cookieHandler(): void {
		if (!IS_DEVELOPMENT()) {
			const cookiesTag = document.querySelector(SELECTORS.cookies_tag) as HTMLScriptElement;
			cookiesTag.src = COOKIE_LINK;
		}
	}

	googleAnalyticsHandler() {
		if (!IS_DEVELOPMENT()) {
			const head = document.getElementsByTagName('head')[0];

			GOOGLE_ANALYTICS_SCRIPTS.forEach((scriptAttributes: any) => {
				const scriptElement = document.createElement('script');

				Object.entries(scriptAttributes).forEach(([key, value]) => {
					scriptElement[key] = value;
				});

				head.appendChild(scriptElement);
			});
		}
	}
}

import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy,
	ViewEncapsulation,
	Output,
	EventEmitter,
	NgZone
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import {} from 'googlemaps';
import { Countries, BREAKPOINTS, STORAGE_NAMES, SELECTORS, CLASSES } from '@root/app.config';
import { Breakpoints, GoogleMapsInfoWindow, Retailer } from '@root/app.interfaces';

declare var $: any;
declare const google: any;

import { ServerDataService } from '@core/services/server-data/server-data.service';
import { Subscription } from 'rxjs';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { RetailerSuccessModalComponent } from '@shared/modals/retailer-success-modal/retailer-success-modal.component';
import { RetailerNoSelectModalComponent } from '@shared/modals/retailer-no-select-modal/retailer-no-select-modal.component';
import { RetailerSelectModalComponent } from '@shared/modals/retailer-select-modal/retailer-select-modal.component';
import { RetailerInfoModalComponent } from '@shared/modals/retailer-info-modal/retailer-info-modal.component';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ModalService } from '@core/services/modal/modal.service';

@Component({
	selector: 'app-retailar-action',
	templateUrl: './retailar-action.component.html',
	styleUrls: ['./retailar-action.component.scss', './retailar-action.responsive.scss',
		'../overview-page/overview-page.component.scss', '../overview-page/overview-page.responsive.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RetailarActionComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() toggleInfoAvailability = new EventEmitter();

	@ViewChild('mapElement') mapElement: ElementRef;
	@ViewChild('postCode') postCode: ElementRef;

	@ViewChild('nonPremiumRetailer') nonPremiumRetailer: ElementRef;
	@ViewChild('premiumRetailer') premiumRetailer: ElementRef;

	@ViewChild('retailerNumber') retailerNumber: ElementRef;

	@ViewChild('retailerContact') retailerContact: ElementRef;
	@ViewChild('retailerSendData') retailerSendData: ElementRef;

	getServerData: Subscription;
	getSettingsData: Subscription;
	getAccess: Subscription;
	getUserTime: Subscription;

	farSettings = {
		hasData: false,
		data: null
	};

	isMobile: boolean;
	shown = false;
	submitted = false;
	loading = false;
	searchRetailerAvailability = false;
	contactRetailerAvailability = false;
	embeddedRetailer = null;

	far = {
		searchMarker: null,
		geocoder: null,
		access: 0,
		search_count: 0,
		map: null,
		markers: [],
		retailers: [],
		isMobile: this.countryCodeService.mobileAndTabletCheck(),
		numberOfSelectedRetailers: 0,
		premiumMarkers: [],
		nonPremiumMarkers: [],
		checkedRetailersID: [],
		center: [],
		formData: {},
		hasResults: false
	};

	findRetailer = this.fb.group({
		first_name: ['', [Validators.required]],
		last_name: ['', [Validators.required]],
		phone: ['', [Validators.required]],
		post_code: ['', [Validators.required]],
		email: ['', [Validators.required]],
		confirm_email: ['', [Validators.required, this.emailValidator('email')]],
		comments: null,
		contact: ['']
	});

	infoWindows: any[] = [];
	mapSelectedRetailer: Retailer | null;
	slideSpeed = 200;
	breakpoints: Breakpoints = BREAKPOINTS;
	checkedRetailers: Retailer[] = [];
	productType: string;

	constructor(
		private fb: FormBuilder,
		private serverDataService: ServerDataService,
		private shareServiceData: ShareService,
		private changeDetection: ChangeDetectorRef,
		private sessionStorageService: SessionStorageService,
		private countryCodeService: SetupPageService,
		private saveSessionBlindService: SaveSessionBlindsService,
		private modalService: ModalService,
		public dialog: MatDialog,
		public zone: NgZone
	) {
	}


	static onConsoleResponse(message): void {
		return console.log(message);
	}

	static Retailer(retailer): any {
		return {
			id: retailer[`id`],
			logo: retailer[`logo`],
			name: retailer[`name`],
			address: retailer[`address`],
			suburb: retailer[`suburb`],
			state: retailer[`state`],
			zip_code: retailer[`zip_code`],
			phone: retailer[`phone`],
			product: retailer[`product`],
			icon: retailer[`icon`],
			latitude: retailer[`latitude`],
			longitude: retailer[`longitude`],
			store_type: retailer[`store_type`],
			car_button: retailer[`car_button`],
			premium: retailer[`premium`],
			swiftee: retailer[`swiftee`],
			service_and_repairs: retailer[`service_and_repairs`],
			display_count: retailer[`display_count`],
			display_coeff: retailer[`display_coeff`],
			boost: retailer[`display_count`] / retailer[`display_coeff`],
			full_address: retailer[`full_address`],

			marker: new google.maps.Marker({
				position: new google.maps.LatLng(retailer[`latitude`], retailer[`longitude`]),
				title: retailer[`name`],
				optimized: true,
				icon: !!+retailer[`premium`] ?
					'assets/icons/new/map-marker-premium-new.svg' :
					'assets/icons/new/map-marker-new.svg'
			})
		};
	}

	static deleteMarkers(markers): void {
		markers.forEach((marker) => {
			marker.marker.setMap(null);
		});
	}

	static getDistance(markers, center): any {
		for (const marker of markers) {
			marker.distance = google.maps.geometry.spherical.computeDistanceBetween(center, marker.marker.getPosition());
		}

		return markers;
	}

	static sortByDistance(a, b): any {
		return (a.distance - b.distance);
	}

	static sortByPriority(a, b): any {
		return (b.priority - a.priority);
	}

	static sortByBoost(a, b): any {
		return (a.boost - b.boost);
	}

	static deleteMarkersOutsideCircle(circle, markers): any {
		const filteredMarkers = [];
		for (const marker of markers) {
			if (marker.distance < circle.radius) {
				filteredMarkers.push(marker);
			}
		}
		return filteredMarkers;
	}

	static shuffleArray(arrayToShuffle): void {
		let currentIndex = arrayToShuffle.length;
		let temporaryValue;
		let randomIndex;

		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;
			temporaryValue = arrayToShuffle[currentIndex];
			arrayToShuffle[currentIndex] = arrayToShuffle[randomIndex];
			arrayToShuffle[randomIndex] = temporaryValue;
		}

		return arrayToShuffle;
	}

	get f() {
		return this.findRetailer.controls;
	}

	ngOnInit(): void {
		this.getRetailersData();
		this.isMobile = this.countryCodeService.mobileAndTabletCheck();
	}

	ngOnDestroy(): void {
		if (this.getSettingsData) {
			this.getSettingsData.unsubscribe();
		}
		if (this.getServerData) {
			this.getServerData.unsubscribe();
		}
		if (this.getAccess) {
			this.getAccess.unsubscribe();
		}
		if (this.getUserTime) {
			this.getUserTime.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		this.far.geocoder = new google.maps.Geocoder();
		this.far.map = new google.maps.Map(this.mapElement.nativeElement, {
			zoom: 4,
			center: new google.maps.LatLng(-26.4390917, 133.281323)
		});
	}

	countrySetter(): any {
		const currentCode = this.countryCodeService.getCountryCode();
		const parsedCode = Countries.filter(item => item.code === currentCode);

		return parsedCode[0];
	}

	emailValidator(confirmEmailInput): any {
		let confirmEmailControl: FormControl;
		let emailControl: FormControl;

		return (control: FormControl) => {
			if (!control.parent) {
				return null;
			}

			if (!confirmEmailControl) {
				confirmEmailControl = control;
				emailControl = control.parent.get(confirmEmailInput) as FormControl;
				emailControl.valueChanges.subscribe(() => {
					confirmEmailControl.updateValueAndValidity();
				});
			}

			if (
				emailControl.value.toLocaleLowerCase() !==
				confirmEmailControl.value.toLocaleLowerCase()
			) {
				return {
					notMatch: true
				};
			}
			return null;
		};
	}

	getRetailersData(): void {
		this.embeddedRetailer = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer);

		if (!this.embeddedRetailer) {
			this.getServerData = this.serverDataService.GetRetailersData().subscribe(res => {
				this.far.retailers = res;
				this.setGoogleAutocomplete();
				this.setRetailerFullAddress();

				this.changeDetection.markForCheck();
			});
		} else {
			setTimeout(() => {
				this.far.retailers = [this.embeddedRetailer];
				this.far.checkedRetailersID = [this.embeddedRetailer.id];
				this.checkedRetailers = [this.embeddedRetailer];
				this.far.numberOfSelectedRetailers = 1;
				this.setGoogleAutocomplete();
				this.setRetailerFullAddress();

				if (this.embeddedRetailer) {
					this.getRetailersByCompanyId();
				}

				$(SELECTORS.overview_add_block)[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			});
		}

		this.getSettingsData = this.serverDataService.GetSettingsData().subscribe(res => {
			this.farSettings.data = res;
			this.farSettings.hasData = true;

			this.changeDetection.markForCheck();
		});
	}

	setGoogleAutocomplete(): void {
		const autocomplete = new google.maps.places.Autocomplete(this.postCode.nativeElement, {
			componentRestrictions: { country: this.countrySetter()?.code },
			types: ['postal_code']
		});

		google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			this.findRetailer.controls.post_code.setValue(place.formatted_address);
		});
	}

	setRetailerFullAddress(): void {
		this.far.retailers.forEach((retailer: Retailer) => {
			retailer.full_address = [retailer.address, retailer.suburb, retailer.state, retailer.zip_code]
				.filter((item) => item)
				.map((item) => (item.trim().replace(/,\s*$/, '')))
				.join(', ');
		});
	}

	getRetailersByCompanyId() {
		this.getSettingsData = this.serverDataService.GetAccess().subscribe(res => {
			this.far.access = res.access;
			this.far.search_count = res.search_count;

			if (res.access) {
				this.geocodeAddress(this.findRetailer.value.post_code, this.far.geocoder, this.far.map);
			} else {
				this.dialog.open(SearchLimitModalComponent);
			}

			this.changeDetection.markForCheck();
		});
	}

	geocodeAddress(addressCode, geoCoder, resultsMap): void {
		const postCodeRegex = /^[0-9]{4}$/;
		if (postCodeRegex.test(addressCode)) {
			addressCode = 'Postcode: ' + addressCode + ' ' + this.countrySetter()?.name;
		}

		geoCoder.geocode({
			address: addressCode,
			componentRestrictions: { country: this.countrySetter()?.code }
		}, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK) {
				resultsMap.setCenter(results[0].geometry.location);
				if (this.far.searchMarker) {
					this.far.searchMarker.setPosition(results[0].geometry.location);
				} else {
					this.far.searchMarker = new google.maps.Marker({
						map: resultsMap,
						position: results[0].geometry.location
					});
				}

				this.findClosestMarkers(results[0]);
				this.mapMarkersHelper();
			}

			if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
				this.modalService.openResponsiveDialog({
					component: RetailerNoSelectModalComponent,
					minHeight: '200px',
					maxWidth: '680px',
					width: '100%',
					height: 'auto'
				});

				this.searchRetailerAvailability = false;
			}
		});
	}

	getMarkers(): any {
		const markers = [];
		if (Array.isArray(this.far.markers) && this.far.markers.length) {
			return this.far.markers;
		} else {
			for (const item of this.far.retailers) {
				const retailer = RetailarActionComponent.Retailer(item);
				markers.push(retailer);
			}
		}

		return markers;
	}

	scrollIntoView(): void {
		setTimeout(() => {
			this.retailerContact.nativeElement.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		});
	}

	setMarkers(map, markers): void {
		markers.forEach(marker => {
			marker.marker.setMap(map);
		});
	}

	zoomMap(map, markers): void {
		const bounds = new google.maps.LatLngBounds();
		markers.forEach(marker => {
			bounds.extend(marker.marker.getPosition());
		});

		bounds.extend(this.far.searchMarker.getPosition());
		map.fitBounds(bounds);
	}

	updateRetailers(): void {
		const nonPremiumRetailersId = [];
		const nonPremiumRetailersCounts = [];

		for (const nonPremiumMarker of this.far.nonPremiumMarkers) {
			nonPremiumRetailersId.push(nonPremiumMarker.id);
			nonPremiumRetailersCounts.push(nonPremiumMarker.display_count);

			const index = this.far.markers.findIndex(marker => marker.id === nonPremiumMarker.id);

			if (index) {
				this.far.markers[index].display_count++;
				this.far.markers[index].boost = this.far.markers[index].display_count / this.far.markers[index].display_coeff;
			}
		}

		this.serverDataService.PostStatsData({
			'nonPremiumMarkersID[]': nonPremiumRetailersId,
			'nonPremiumMarkersCOUNT[]': nonPremiumRetailersCounts,
			action: 'update_retailers'
		}).subscribe();
	}

	createCircle(radius, centerPoint): any {
		return new google.maps.Circle({
			center: centerPoint,
			map: this.far.map,
			radius: radius * 1000,
			visible: false
		});
	}

	getClosestPremiumMarkers(allPremiumMarkers, centerLocation): any {
		const premiumCircle = this.createCircle(this.farSettings.data.max_premium_distance, centerLocation);

		let premiumMarkers = RetailarActionComponent.deleteMarkersOutsideCircle(premiumCircle, allPremiumMarkers);

		premiumMarkers = this.filterByProduct(premiumMarkers);
		premiumMarkers.sort(RetailarActionComponent.sortByDistance);
		premiumMarkers = premiumMarkers.splice(0, this.farSettings.data.premium_number);

		return premiumMarkers;
	}

	getClosestNonPremiumMarkers(allNonPremiumMarkers, centerLocation): any {
		let nonPremiumCircle = this.createCircle(this.farSettings.data.standart_non_premium_distance, centerLocation);
		let nonPremiumMarkers = RetailarActionComponent.deleteMarkersOutsideCircle(nonPremiumCircle, allNonPremiumMarkers);

		if (nonPremiumMarkers.length < this.farSettings.data.non_premium_number) {
			nonPremiumCircle = this.createCircle(this.farSettings.data.max_non_premium_distance, centerLocation);
			nonPremiumMarkers = RetailarActionComponent.deleteMarkersOutsideCircle(nonPremiumCircle, allNonPremiumMarkers);
			nonPremiumMarkers = this.filterByProduct(nonPremiumMarkers);
			nonPremiumMarkers.sort(RetailarActionComponent.sortByDistance);
			nonPremiumMarkers = nonPremiumMarkers.splice(0, this.farSettings.data.non_premium_max_additional_search_quantity);
		} else {
			nonPremiumMarkers = this.filterByProduct(nonPremiumMarkers);
			nonPremiumMarkers.sort(RetailarActionComponent.sortByDistance);
			nonPremiumMarkers = nonPremiumMarkers.splice(0, this.farSettings.data.non_premium_number);
		}

		return nonPremiumMarkers;
	}

	findClosestMarkers(center): void {
		RetailarActionComponent.deleteMarkers(this.far.markers);
		this.far.markers = this.getMarkers();
		this.far.center.push(center);

		const closestMarkers = RetailarActionComponent.getDistance(this.far.markers, center.geometry.location);
		const premiumMarkers = closestMarkers.filter(marker => +marker.premium);

		if (this.farSettings.hasData) {
			this.getBlindsProductType();

			if (!this.embeddedRetailer) {
				this.far.premiumMarkers = this.getClosestPremiumMarkers(premiumMarkers, center.geometry.location);
				this.far.nonPremiumMarkers = this.getClosestNonPremiumMarkers(closestMarkers, center.geometry.location);
			} else {
				this.far.premiumMarkers = this.filterByProduct(premiumMarkers);
				this.far.nonPremiumMarkers = this.filterByProduct(closestMarkers);
			}

			if (this.farSettings.data.premium_display) {
				const combinedMarkers = this.far.nonPremiumMarkers.concat(this.far.premiumMarkers);

				this.setMarkers(this.far.map, combinedMarkers);
				this.zoomMap(this.far.map, combinedMarkers);
			} else {
				this.setMarkers(this.far.map, this.far.nonPremiumMarkers);
				this.zoomMap(this.far.map, this.far.nonPremiumMarkers);
			}

			this.sendReporting();
			this.updateRetailers();
			$(SELECTORS.request_button).removeClass(CLASSES.loading);

			if (!this.far.nonPremiumMarkers.length && !this.far.premiumMarkers.length) {
				this.modalService.openResponsiveDialog({
					component: RetailerNoSelectModalComponent,
					minHeight: '200px',
					maxWidth: '680px',
					width: '100%',
					height: 'auto'
				});

				this.searchRetailerAvailability = false;
				this.toggleInfoAvailability.emit(!this.embeddedRetailer);
				return;
			}

			this.shown = !!this.embeddedRetailer;
			this.searchRetailerAvailability = true;
			this.far.hasResults = true;
			this.scrollIntoView();
		}
	}

	sendReporting(): any {
		const premiumRetailersId = [];
		const nonPremiumRetailersId = [];

		for (const item of this.far.nonPremiumMarkers) {
			nonPremiumRetailersId.push(item.id);
		}

		for (const item of this.far.premiumMarkers) {
			premiumRetailersId.push(item.id);
		}

		this.serverDataService.PostStatsData({
			'premiumMarkers[]': premiumRetailersId,
			'nonPremiumMarkers[]': nonPremiumRetailersId,
			center: this.far.center,
			origin: '3D Configurator',
			action: 'geolocator_reporting',
			search_count: this.far.search_count,
			access: this.far.access
		}).subscribe();
	}

	onSubmit(): void {
		this.submitted = true;

		if (this.findRetailer.invalid) {
			setTimeout(this.scrollToError.bind(this));
			return;
		}

		if (this.embeddedRetailer) {
			this.onSendDataByCompanyId(event, this.far.geocoder);
			return;
		}

		this.loading = true;
		this.far.checkedRetailersID = [];
		this.checkedRetailers = [];
		this.far.numberOfSelectedRetailers = 0;
		this.mapSelectedRetailer = null;

		this.far.nonPremiumMarkers.forEach(e => {
			e.marker.checked = false;
		});
		this.far.premiumMarkers.forEach(e => {
			e.marker.checked = false;
		});

		delete this.findRetailer.value.confirm_email;

		this.far.formData = this.findRetailer.value;

		this.getSettingsData = this.serverDataService.GetAccess().subscribe(res => {
			this.far.access = res.access;
			this.far.search_count = res.search_count;

			if (res.access) {
				this.geocodeAddress(this.findRetailer.value.post_code, this.far.geocoder, this.far.map);
			} else {
				this.dialog.open(SearchLimitModalComponent);
			}

			this.loading = false;
			this.changeDetection.markForCheck();
		});
	}

	onSendDataByCompanyId(event, geocoder) {
		delete this.findRetailer.value.confirm_email;
		this.far.formData = this.findRetailer.value;

		let addressCode = this.findRetailer.value.post_code;
		const postCodeRegex = /^[0-9]{4}$/;

		if (postCodeRegex.test(addressCode)) {
			addressCode = 'Postcode: ' + addressCode + ' ' + this.countrySetter()?.name;
		}

		geocoder.geocode({
			address: addressCode,
			componentRestrictions: { country: this.countrySetter()?.code }
		}, (results, status) => {
			if (results[0].types.every(type => type === 'country' || type === 'political')) {
				status = google.maps.GeocoderStatus.ZERO_RESULTS;
			}

			if (status === google.maps.GeocoderStatus.OK) {
				this.far.center.splice(0, 1, results[0]);
				this.onSendData(event);
			}

			if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
				this.modalService.openResponsiveDialog<boolean>({
					component: RetailerNoSelectModalComponent,
					data: true,
					minHeight: '200px',
					maxWidth: '680px',
					width: '100%',
					height: 'auto'
				});
			}
		});
	}

	scrollToError() {
		const errorMessage = $(SELECTORS.retailer_form_section_message)[0];
		const incorrectSection = errorMessage.closest(SELECTORS.retailer_form_section);

		const offset = $(SELECTORS.header)[0].clientHeight + 10;
		const top = incorrectSection.getBoundingClientRect().top + window.pageYOffset - offset;

		if (window.pageYOffset > top) {
			window.scrollTo({ top, behavior: 'smooth' });
		}
	}

	mapMarkersHelper(): any {
		for (const nonPremiumMarker of this.far.nonPremiumMarkers) {
			this.retailerPointerHelper(nonPremiumMarker, '');
		}

		for (const premiumMarker of this.far.premiumMarkers) {
			this.retailerPointerHelper(premiumMarker, 'premium');
		}
	}

	retailerPointerHelper(data, type): void {
		const infoWindowContent = `
            <div class="mc-info-window">
                <div class="mc-info-window-header">${ data.name }</div>
                <div class="mc-info-window-address">${ data.full_address }</div>
            </div>`;
		const infoWindow = new google.maps.InfoWindow({
			content: infoWindowContent
		});

		const isInfoWindowOpen = (window: GoogleMapsInfoWindow) => {
			const map = window.getMap();
			return (map !== null && typeof map !== 'undefined');
		};

		this.infoWindows.push(infoWindow);

		data.marker.addListener('click', () => {
			if (isInfoWindowOpen(infoWindow)) {
				this.closeAllInfoWindows();
				this.mapSelectedRetailer = null;
			} else {
				this.mapSelectedRetailer = data;
				this.closeAllInfoWindows();
				infoWindow.open(this.far.map, data.marker);
				const listener = this.far.map.addListener('click', () => {
					this.mapSelectedRetailer = null;
					infoWindow.close();
					listener.remove();
				});
			}
		});
	}

	onClickRetailerEvent(retailer): void {
		if (!!retailer.marker.checked === false &&
			this.far.numberOfSelectedRetailers < 2) {
			retailer.marker.checked = true;
			this.far.checkedRetailersID.push(retailer.id);
			this.checkedRetailers.push(retailer);
			++this.far.numberOfSelectedRetailers;

			this.changeRetailerMapMarker();
		} else if (!!retailer.marker.checked === true) {
			retailer.marker.checked = false;
			--this.far.numberOfSelectedRetailers;

			this.far.checkedRetailersID.splice(this.far.checkedRetailersID.indexOf(retailer.id), 1);
			this.checkedRetailers.splice(this.checkedRetailers.indexOf(retailer), 1);
			this.changeRetailerMapMarker();
		}

		if (this.far.numberOfSelectedRetailers >= 2) {
			this.retailerSendData.nativeElement.scrollIntoView({
				behavior: 'smooth'
			});
		}
	}

	onHoverRetailerEvent(event, retailer): void {
		if (!!retailer.marker.checked === false &&
			this.far.numberOfSelectedRetailers < 2) {

			retailer.marker.setAnimation(google.maps.Animation.BOUNCE);
			setTimeout(() => {
				retailer.marker.setAnimation(null);
			}, 700);
		}
	}

	toggleShow(): void {
		this.shown = !this.shown;
		this.mapSelectedRetailer = null;
		this.closeAllInfoWindows();
	}

	getCountryCode(): any {
		return this.sessionStorageService.getSession(STORAGE_NAMES.zip_country_code);
	}

	onSendData(e): void {
		if (this.findRetailer.invalid) {
			document.querySelector(SELECTORS.retailer).scrollIntoView({ block: 'start', behavior: 'smooth' });
			return;
		}

		this.findRetailer.value.post_code = this.findRetailer.value.post_code.match(/\d+/)[0];
		this.far.formData = this.findRetailer.value;

		const sessionStorageBlinds = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const button = e.submitter ? $(e.submitter) : $(e.currentTarget);
		if (this.far.numberOfSelectedRetailers === 0) {
			const currentMarkersCount = this.far.nonPremiumMarkers.length + this.far.premiumMarkers.length;

			this.modalService.openResponsiveDialog<boolean>({
				component: RetailerSelectModalComponent,
				data: currentMarkersCount === 1,
				minHeight: '200px',
				maxWidth: '680px',
				width: '100%',
				height: 'auto'
			});

			return;
		}

		const embedKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embed_key);
		const sceneIVScreenshot = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_iv_screenshot);
		const data = {
			action: '3DConfigurator',
			center_latitude: this.far.center[0].geometry.location.lat(),
			center_longitude: this.far.center[0].geometry.location.lng(),
			retailers: this.far.checkedRetailersID,
			country: this.getCountryCode(),
			embed_key: embedKey,
			origin: embedKey ? 'embed' : 'default',
			blinds: [],
			iv_screenshot: sceneIVScreenshot ? sceneIVScreenshot : '',
			time: 0
		};
		for (const blind of sessionStorageBlinds) {
			if (blind.has_name) {
				data.blinds.push(blind);
			}
		}
		const sessionKey = this.setupSessionKeyForSavingHandler();
		button.addClass(CLASSES.loading);
		this.shareServiceData.getUserTime.subscribe(res => {
			data.time = res;
		});

		this.serverDataService.PostData({ ...data, ...this.far.formData }, sessionKey).subscribe(res => {
			this.sessionStorageSessionKeyHelper();
			this.sessionStorageService.setBlindData(true, STORAGE_NAMES.zip_blind_progress_saved);
			RetailarActionComponent.onConsoleResponse(res.message);
			this.shareServiceData.setRetailerRequest(e);
			this.saveSessionBlindService.preventReloadPage(false);

			this.zone.run(() => {
				this.modalService.openResponsiveDialog<any>({
					component: RetailerSuccessModalComponent,
					data: this.far,
					maxWidth: '1000px',
					width: '80%',
					panelClass: ''
				});
			});
		});
	}

	onScrollToAddContact(): void {
		if (this.far.numberOfSelectedRetailers === 0) {
			const currentMarkersCount = this.far.nonPremiumMarkers.length + this.far.premiumMarkers.length;

			this.modalService.openResponsiveDialog<boolean>({
				component: RetailerSelectModalComponent,
				data: currentMarkersCount === 1,
				minHeight: '200px',
				maxWidth: '680px',
				width: '100%',
				height: 'auto'
			});

			return;
		}

		this.contactRetailerAvailability = true;

		setTimeout(() => {
			$(SELECTORS.overview_add_block)[0].scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		});
	}

	setupSessionKeyForSavingHandler(): any {
		const hasSavedSession = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		const tempSessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_temp_session);
		const sessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);

		return hasSavedSession ? sessionKey : tempSessionKey;
	}

	sessionStorageSessionKeyHelper(): void {
		this.sessionStorageService.setBlindData(this.setupSessionKeyForSavingHandler(), STORAGE_NAMES.zip_blind_session);
		this.shareServiceData.setLinkSessionKey(this.setupSessionKeyForSavingHandler());

		this.sessionStorageService.setBlindData(true, STORAGE_NAMES.zip_blind_session_saved);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_temp_session);
	}

	closeAllInfoWindows(): void {
		this.infoWindows.forEach(infoWindow => infoWindow.close());
	}

	onRetailerCardHandler(event: Event, cardsList: HTMLElement): void {
		const header = $(event.currentTarget);
		const card = header.parent();
		const isOpenedCard = card.hasClass('open');

		if (cardsList) {
			for (let card of $(cardsList).children()) {
				$(card).removeClass('open');
				$(card).children().next().slideUp(this.slideSpeed);
			}
		}

		if (isOpenedCard) {
			card.removeClass('open');
			header.next().slideUp(this.slideSpeed);
		} else {
			card.addClass('open');
			header.next().slideDown(this.slideSpeed);
		}

		this.closeAllInfoWindows();
	}

	onCloseRetailerCard(): void {
		this.mapSelectedRetailer = null;
	}

	changeRetailerMapMarker() {
		const retailers = [...this.far.premiumMarkers, ...this.far.nonPremiumMarkers];

		for (let retailer of retailers) {
			const folder = 'assets/icons/new/';
			let icon = folder + (+retailer.premium ? retailer.marker.checked ? 'map-marker-selected-premium.svg' :
				'map-marker-premium-new.svg' : retailer.marker.checked ? 'map-marker-selected.svg' : 'map-marker-new.svg');

			if (this.far.numberOfSelectedRetailers >= 2 && !retailer.marker.checked) {
				icon = folder + (+retailer.premium ? 'map-marker-disabled-premium.svg' :
					'map-marker-disabled.svg');
			}

			retailer.marker.setIcon(icon);
		}
	}

	getBlindsProductType(): void {
		const blindsData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const productTypes = blindsData.reduce((acc, blind) => !acc.includes(blind.type) ? [...acc, blind.type] : acc, []);
		this.productType = productTypes.length === 2 ? 'all' : productTypes[0];
	}

	filterByProduct(array): any {
		return array.filter(item => item.product === this.productType || item.product === 'all');
	}

	onOpenRetailerInfoModal() {
		this.modalService.openResponsiveDialog({
			component: RetailerInfoModalComponent,
			maxWidth: '280px',
			width: '100%',
			panelClass: ''
		});
	}
}

@Component({
	selector: 'app-search-limit-modal',
	templateUrl: './modals/search-limit-modal.html',
	styleUrls: ['./retailar-action.component.scss', './retailar-action.responsive.scss']
})
export class SearchLimitModalComponent {
	constructor(public dialogRef: MatDialogRef<SearchLimitModalComponent>) {
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}

